import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { ContextProvider } from './context/context';
import { Fallback } from './components/Fallback/Fallback';
import { GlobalStyle } from './index.styled';
import { Router } from './Router/Router';
import Layout from './components/Layout/Layout';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ScrollToTop } from './Router/ScrollToTop/ScrollToTop';

const root: ReactDOM.Root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <ContextProvider>
        <HelmetProvider>
          <ErrorBoundary FallbackComponent={Fallback}>
            <Layout>
              <Helmet>
                <meta charSet="UTF-8" key="charset" />
                <meta name="viewport" key="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="title" key="title" content="SweetSpot Cukrászblog" />
                <meta
                  name="description"
                  key="description"
                  content="Üdvözlünk az Édesszájúak bolygóján, ahol a leszállópálya sütimorzsákkal van kikövezve. Az útjelzőtáblák pedig
        segítenek majd eligazodni a krémes torták, puha piskóták, omlós kekszek, ínycsiklandó piték, selymes
        csokoládéörvények, vagy éppen a habkönnyű desszertek között."
                />
                <meta
                  name="keywords"
                  key="keywords"
                  content="SweetSpot, cukrászat, megrendelés, Szabadka, torták, édességek"
                />
                <meta name="author" key="author" content="SweetSpot Cukrászblog" />
                <meta name="robots" key="robots" content="index,follow" />
                <meta name="og:title" key="og:title" content="SweetSpot Cukrászblog" />
                <meta
                  name="og:description"
                  key="og:description"
                  content="Üdvözlünk az Édesszájúak bolygóján, ahol a leszállópálya sütimorzsákkal van kikövezve. Az útjelzőtáblák pedig
        segítenek majd eligazodni a krémes torták, puha piskóták, omlós kekszek, ínycsiklandó piték, selymes
        csokoládéörvények, vagy éppen a habkönnyű desszertek között."
                />
                <meta property="og:image" key="og:image" content="https://sweetspot.rs/favicon.ico" />
                <meta property="og:url" key="og:url" content="https://sweetspot.rs" />
                <title key="title-meta">SweetSpot Cukrászblog</title>
              </Helmet>
              <GlobalStyle />
              <Router />
              <ToastContainer />
            </Layout>
          </ErrorBoundary>
        </HelmetProvider>
      </ContextProvider>
    </BrowserRouter>
  </StrictMode>,
);

serviceWorkerRegistration.register();

reportWebVitals();
