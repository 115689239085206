import { Anchor, Box, ResponsiveContext, Text } from 'grommet';
import styled from 'styled-components';

export const CONTENT: { [section: string]: any[] } = {
  Rólunk: [{ szerző: '/author' }, { támogató: '/' }, { fenntartó: 'https://www.linkedin.com/in/la-balint/' }],
  Kapcsolat: [{ 'e-mail': '/contact' }, { közösségi: '/#header' }, { üzenet: '/contact' }],
};

const StyledAnchor = styled(Anchor)`
  font-weight: 300;
  font-size: 1rem;
`;

const FooterContent = (): JSX.Element => (
  <ResponsiveContext.Consumer>
    {(size) => (
      <Box
        direction="row"
        align="center"
        justify="between"
        gap={size !== 'xsmall' && size !== 'small' ? 'xlarge' : 'small'}
      >
        <Box gap="medium">
          <Text weight="bold" size="small">
            Rólunk
          </Text>
          <StyledAnchor href="/author" size="small" color="black">
            szerző
          </StyledAnchor>
          <StyledAnchor href="/#search-form" size="small" color="black">
            receptek
          </StyledAnchor>
          <StyledAnchor href="https://www.linkedin.com/in/la-balint/" target="_blank" size="small" color="black">
            fenntartó
          </StyledAnchor>
        </Box>
        <Box gap="medium">
          <Text weight="bold" size="small">
            Kapcsolat
          </Text>
          <StyledAnchor
            href="mailto:sweetspot.subotica@gmail.com"
            title="E-mail: sweetspot.subotica@gmail.com "
            size="small"
            color="black"
          >
            e-mail
          </StyledAnchor>
          <StyledAnchor href="/contact" size="small" color="black">
            üzenetküldés
          </StyledAnchor>
          <StyledAnchor href="tel:+381637693041" title="Telefon: +381-63/76-93-041" size="small" color="black">
            telefon
          </StyledAnchor>
        </Box>
      </Box>
    )}
  </ResponsiveContext.Consumer>
);

export default FooterContent;
