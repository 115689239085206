import { FC, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { ResponsiveContext, Grid, Spinner, Box, InfiniteScroll, Pagination } from 'grommet';
import { toast } from 'react-toastify';

import {
  Category,
  fetchRecipesByCategory,
  fetchRecipesByKeyword,
  Recipe,
  Recipe as RecipeProps,
} from '../../api/backendServer';
import Card from '../Card/Card';
import { theme } from '../../theme';
import { ITEMS_PER_PAGE } from '../../context/constants';

const Recipes: FC = () => {
  const [fetching, setFetching] = useState(false);
  const [recipes, setRecipes] = useState<RecipeProps[]>([]);
  const [pagination, setPagination] = useState({ currentPage: 1, pageCount: 0, totalCount: 0 });
  const { currentPage, pageCount, totalCount } = pagination;

  const [searchParams] = useSearchParams();
  const category = searchParams.get('category') as Category;
  const search = searchParams.get('search') as string;

  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    const fetchRecipes = async () => {
      setFetching(true);
      try {
        const result = await (category
          ? fetchRecipesByCategory(category, currentPage)
          : fetchRecipesByKeyword(search, currentPage));
        setRecipes(result.items);
        setPagination({
          currentPage,
          pageCount: Math.ceil(result.totalCount / ITEMS_PER_PAGE),
          totalCount: result.totalCount,
        });
      } catch (error) {
        const message =
          (error as AxiosError).response?.status === 404
            ? `Nem találtunk recepteket a megadott feltételekkel: ${search}`
            : `Hiba a receptek betöltésekor: ${(error as Error).message}`;
        toast.error(message);
      } finally {
        setFetching(false);
      }
    };

    fetchRecipes();
  }, [category, search, currentPage, pageCount]);

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <>
          {fetching ? (
            <Box width="100%" height="50vh" align="center" justify="center">
              <Spinner size="medium" color={theme.colors.primary} align="center" />
            </Box>
          ) : (
            <Grid
              pad={{ horizontal: !size.includes('small') ? 'large' : 'small' }}
              align="center"
              justify="center"
              columns={!size.includes('small') ? { count: 'fill', size: 'medium' } : 'auto'}
              gap="medium"
            >
              <InfiniteScroll items={recipes} replace>
                {(recipe: Recipe) => (
                  <Card
                    key={recipe.title}
                    name={recipe.title}
                    description={recipe.subtitle}
                    image={`/assets/${recipe.files.find((file) => file.primary)?.filename}`}
                    onCardClick={() => navigate(`/posts/${recipe.id}`)}
                  />
                )}
              </InfiniteScroll>
            </Grid>
          )}
          <Box align="center" justify="center">
            <Pagination
              a11yTitle="Recept oldalak"
              alignSelf="center"
              numberItems={totalCount}
              step={ITEMS_PER_PAGE}
              size={!size.includes('small') ? 'large' : 'medium'}
              margin="small"
              page={currentPage}
              onChange={({ page }) => setPagination({ ...pagination, currentPage: page })}
            />
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default Recipes;
