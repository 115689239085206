import { useState } from 'react';
import { Box, Form, FormField, TextInput, Button, Text, TextArea, Spinner } from 'grommet';

import { theme } from '../../theme';
import { sendMessage } from '../../api/backendServer';
import { toast } from 'react-toastify';

export const EMAIL_REGEX: RegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export type Email = {
  address: string;
  message: string;
  date: Date;
};

const Contact = (): JSX.Element => {
  const [address, setAddress] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const isValidEmail = (): boolean => EMAIL_REGEX.test(address);

  const handleSendMessage = (): void => {
    setLoading(true);
    sendMessage({ address, message, date: new Date() })
      .then((response) => {
        if (typeof response === 'string') {
          toast.info(`Üzenet sikeresen elküldve! Hamarosan válaszolunk. :)`);
          setAddress('');
          setMessage('');
        }
      })
      .catch(() =>
        toast.error('Nem sikerült elküldeni az üzenetet. Válaszd a közvetlen e-mail küldés opciót a menüből!'),
      )
      .finally(() => setLoading(false));
  };

  if (loading)
    return (
      <Box width="100%" height="50vh" align="center" justify="center">
        <Spinner size="medium" color={theme.colors.primary} align="center" />
      </Box>
    );

  return (
    <Box align="center" justify="center" pad="medium" responsive>
      <Box width="medium">
        <Text size="large" margin="small" style={{ fontWeight: 800 }}>
          Írj nekünk üzenetet
        </Text>
        <Form onSubmit={handleSendMessage}>
          <FormField
            label="E-mail cím"
            name="email"
            value={address}
            onChange={({ target: { value } }) => setAddress(value)}
          >
            <TextInput name="email" />
          </FormField>
          <FormField label="Üzenet" name="text" value={message} onChange={({ target: { value } }) => setMessage(value)}>
            <TextArea name="text" rows={8} />
          </FormField>
          <Box direction="row" justify="between" margin={{ top: 'medium' }}>
            <Button
              type="submit"
              label="Elküld"
              color={theme.colors.primary}
              primary
              disabled={!isValidEmail() || message.length < 5}
            />
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default Contact;
