import 'react-toastify/dist/ReactToastify.css';
import { Box } from 'grommet';
import { Favorite } from 'grommet-icons';

import { theme } from '../../theme';
import Categories from '../Categories/Categories';
import Intro from '../Intro/Intro';
import Search from '../Search/Search';
import Slider from '../Slider/Slider';
import { MAIN_SLIDER_DATA } from '../Slider/sliderData';

const App = (): JSX.Element => (
  <>
    <Slider sliderData={MAIN_SLIDER_DATA} />
    <Box align="center" pad="large">
      <Intro titleSize="medium" />
      <Favorite color={theme.colors.primary} size="large" />
    </Box>
    <Box align="center" pad="medium">
      <Search />
      <Categories />
    </Box>
  </>
);

export default App;
