import { useState } from 'react';
import { createSearchParams, NavigateFunction, useNavigate } from 'react-router-dom';
import { Box, Form, FormField, Button, Text, TextInput } from 'grommet';

import { theme } from '../../theme';

const Search = (): JSX.Element => {
  const [search, setSearch] = useState<string>('');

  const navigate: NavigateFunction = useNavigate();

  const searchClickedHandler = (): void => {
    navigate({
      pathname: 'posts',
      search: createSearchParams({
        search,
      }).toString(),
    });
  };

  return (
    <Box id="search-form" align="center" justify="center" pad="medium" margin={{ bottom: 'medium' }} responsive>
      <Text size="large" margin="small" textAlign="center" style={{ fontWeight: 800 }}>
        Keresgélj a receptek között!
      </Text>
      <Form onSubmit={searchClickedHandler}>
        <FormField
          name="search"
          label="Adj meg egy kulcsszót (pl. málna)..."
          onChange={({ target: { value } }) => setSearch(value)}
          style={{ textAlign: 'center' }}
        >
          <TextInput name="search" value={search} placeholder="pl. kardamom" />
        </FormField>
        <Box direction="row" align="center" justify="center" margin={{ top: 'medium' }}>
          <Button type="submit" label="Keresés" color={theme.colors.primary} primary />
        </Box>
      </Form>
    </Box>
  );
};

export default Search;
