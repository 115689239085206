import { grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';

export const theme = {
  colors: {
    primary: '#40909A',
    secondary: '#757575',
    background: '#f7f6f5',
    foreground: '#FFF',
    brownish: '#E0C6BF',
    greenish: '#E1E6D9',
    white: '#FFFFFF',
  },
};

export const customTheme = deepMerge(grommet, {
  global: {
    breakpoints: {
      xsmall: {
        value: 400,
      },
    },
  },
  heading: {
    extend: () => `margin-top: 12px; margin-bottom: 12px;`,
  },
  paragraph: {
    extend: () => `font-weight: 300; margin-top: 0;`,
    xxlarge: {
      size: '28px',
    },
  },
  textInput: {
    placeholder: {
      extend: () => `color: #44444`,
    },
  },
});
