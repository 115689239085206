import { Category } from '../../api/backendServer';
import { CardProps } from '../Card/Card';

export const categories: CardProps[] = [
  {
    name: 'Torták',
    image: './images/category_cakes.png',
    description: 'Szülinapi, ünneplős, vagy csak megérdemlem...',
    category: Category.CAKES,
  },
  {
    name: 'Kekszek',
    image: './images/category_cookies.png',
    description: 'Soha többé boltit, inkább saját kútfőből...',
    category: Category.COOKIES,
  },
  {
    name: 'Desszertek',
    image: './images/category_desserts.png',
    description: 'Habkönnyűek, mutatósak és minden alkalomra...',
    category: Category.DESSERTS,
  },
  {
    name: 'Piték',
    image: './images/category_pies.png',
    description: 'Rácsos tetejű, cakkos szélű kedvenceim...',
    category: Category.PIES,
  },
  {
    name: 'Kevert sütemények',
    image: './images/category_cupcakes_muffins_brownies.png',
    description: 'Egyszerű, de annál nagyszerűbb...',
    category: Category.CUPCAKES_MUFFINS_BROWNIES,
  },
  {
    name: 'Péksütemények',
    image: './images/category_pastries.png',
    description: 'Többé már ez sem lehet számodra mumus...',
    category: Category.PASTRIES,
  },
];
