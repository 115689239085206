import SlickSlider from 'react-slick';
import { Box, ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import { FC } from 'react';

import SliderImage from './Slider.image';

const StyledSlickSlider = styled(SlickSlider)`
  img {
    width: 100%;
    height: auto;
  }
`;

type SliderProps = {
  sliderData: { [imagePath: string]: string };
};

const Slider: FC<SliderProps> = ({ sliderData }) => {
  const settings = {
    infinite: true,
    slidesToScroll: 1,
    speed: 1600,
    autoplay: true,
    adaptiveHeight: true,
  };

  const getGalleryItems = (): JSX.Element[] =>
    Object.entries(sliderData).map(([image, name], index) => (
      <SliderImage key={index} src={image} name={name} alt={name} />
    ));

  const getNumOfItemsToShow = (size: string): number =>
    size === 'xsmall' ? 1 : size === 'small' ? 2 : size === 'medium' ? 3 : 4;

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box pad="medium">
          <StyledSlickSlider slidesToShow={getNumOfItemsToShow(size)} {...settings}>
            {getGalleryItems()}
          </StyledSlickSlider>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default Slider;
