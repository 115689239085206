import { Box, Text, Grid, Carousel, ResponsiveContext, Spinner } from 'grommet';
import { useCallback, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { fetchRecipeById, Recipe as RecipeProps } from '../../api/backendServer';
import { theme } from '../../theme';
import { StyledImage } from '../Card/Card';

export enum CategoryMap {
  CAKES = 'Torták',
  COOKIES = 'Kekszek',
  DESSERTS = 'Desszertek',
  PIES = 'Piték',
  CUPCAKES_MUFFINS_BROWNIES = 'Vegyes sütemények',
  PASTRIES = 'Péksütemények',
}

const StyledRecipe = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCategory = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Recipe = (): JSX.Element => {
  const [recipe, setRecipe] = useState<RecipeProps>();
  const [fetching, setFetching] = useState<boolean>(false);
  const { files = [], ingredientParts = [], intro = '', preparation = '', subtitle = '', title = '' } = recipe ?? {};

  const { id } = useParams();

  const navigate: NavigateFunction = useNavigate();
  const handleNavigate = useCallback(() => navigate('/'), [navigate]);

  useEffect(() => {
    if (!recipe && id && !fetching) {
      setFetching(true);
      fetchRecipeById(id)
        .then(setRecipe)
        .catch(handleNavigate)
        .finally(() => setFetching(false));
    }
  }, [id, recipe, fetching, navigate]);

  if (recipe) {
    const ingredients: { [part: string]: string[] } = {};
    ingredientParts.forEach((part) => {
      const [section, details] = part.split('|');
      ingredients[section] = [];
      details.split(';').forEach((ingredient) => ingredients[section].push(ingredient.trim()));
    });

    return (
      <ResponsiveContext.Consumer>
        {(size) => (
          <>
            <StyledRecipe>
              {!size.includes('small') ? (
                <Grid
                  responsive
                  rows={['large', 'auto', 'auto', 'auto', 'auto']}
                  columns={['medium', 'medium']}
                  areas={[
                    { name: 'intro', start: [0, 0], end: [1, 0] },
                    { name: 'titles', start: [0, 1], end: [1, 1] },
                    { name: 'ingredients', start: [0, 2], end: [1, 2] },
                    { name: 'preparation', start: [0, 3], end: [1, 3] },
                    { name: 'images', start: [0, 4], end: [1, 4] },
                  ]}
                  style={{ letterSpacing: '0.05rem' }}
                >
                  <Box gridArea="intro">
                    <StyledImage
                      src={`/assets/${files.find((file) => file.primary)?.filename}`}
                      alt={title}
                      fit="cover"
                      style={{ aspectRatio: '1 / 1', borderTopLeftRadius: '12px', borderTopRightRadius: '16px' }}
                    />
                    <StyledCategory
                      style={{
                        background: theme.colors.primary,
                        color: theme.colors.white,
                        fontWeight: 700,
                        textTransform: 'uppercase',
                        paddingRight: '3px',
                      }}
                    >
                      {CategoryMap[recipe.category]}
                    </StyledCategory>
                    <Text
                      style={{
                        color: theme.colors.white,
                        background: theme.colors.primary,
                        fontStyle: 'italic',
                        padding: '8px',
                        paddingBottom: '20px',
                      }}
                    >
                      {intro}
                    </Text>
                  </Box>
                  <Box gridArea="titles" background="light-4" pad="medium">
                    <h2 style={{ fontFamily: 'Tomatoes', paddingBottom: '1rem' }}>{title}</h2>
                    <h3>{subtitle}</h3>
                  </Box>
                  <Box gridArea="ingredients" wrap direction="row" pad="small" background={theme.colors.brownish}>
                    {Object.entries(ingredients).map(([part, ingredientList]) => (
                      <ul key={part}>
                        <b>{part}</b>
                        {ingredientList.map(
                          (ingredient) => ingredient.trim() && <li key={ingredient}>{ingredient}</li>,
                        )}
                      </ul>
                    ))}
                  </Box>
                  <Box gridArea="preparation" background={theme.colors.greenish} pad="medium">
                    {preparation
                      .replace(/(\.[\s]{0,1},)/g, '.')
                      .replace(/\d\.\s/g, '')
                      .split(/(?<!kb|Kb)\.\s/g)
                      .map((sentence, index, sentences) => (
                        <Text key={index} margin={{ top: '6px' }}>
                          {sentence && `${sentence}`}
                          {index !== sentences.length - 1 && '.'}
                        </Text>
                      ))}
                  </Box>
                  <Box gridArea="images" fill height="auto">
                    <Carousel play={2500}>
                      {files.map((file) => (
                        <StyledImage key={file.filename} src={`/assets/${file.filename}`} alt={title} fit="cover" />
                      ))}
                    </Carousel>
                  </Box>
                </Grid>
              ) : (
                <Box>
                  <StyledImage
                    src={`/assets/${files.find((file) => file.primary)?.filename}`}
                    alt={title}
                    fit="cover"
                    style={{ aspectRatio: '1/1' }}
                  />
                  <StyledCategory
                    style={{
                      background: theme.colors.primary,
                      color: theme.colors.white,
                      fontWeight: 700,
                      textTransform: 'uppercase',
                      paddingRight: '3px',
                    }}
                  >
                    {CategoryMap[recipe.category]}
                  </StyledCategory>
                  <Text
                    style={{
                      color: theme.colors.white,
                      background: theme.colors.primary,
                      fontStyle: 'italic',
                      padding: '8px',
                      paddingBottom: '20px',
                    }}
                  >
                    {intro}
                  </Text>
                  <Box background="light-4" pad="medium">
                    <h2
                      style={{
                        fontFamily: 'Tomatoes',
                        paddingTop: '0.5rem',
                        paddingBottom: '1rem',
                        textAlign: 'center',
                      }}
                    >
                      {title}
                    </h2>
                    <h4
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {subtitle}
                    </h4>
                  </Box>
                  <Box pad={{ left: 'none', right: 'medium' }} background={theme.colors.brownish}>
                    {Object.entries(ingredients).map(([part, ingredientList]) => (
                      <ul key={part}>
                        <b>{part}</b>
                        {ingredientList.map(
                          (ingredient) => ingredient.trim() && <li key={ingredient}>{ingredient}</li>,
                        )}
                      </ul>
                    ))}
                  </Box>
                  <Box background={theme.colors.greenish} pad="medium">
                    {preparation
                      .replace(/(<[/]?p>)/g, '')
                      .replace(/(\.[\s]{0,1},)/g, '.')
                      .split(/(?<!kb|Kb)\.\s/g)
                      .map((sentence, index) => (
                        <Text key={index} margin={{ top: '6px' }}>
                          {sentence}.
                        </Text>
                      ))}
                  </Box>
                  <Box>
                    <Carousel play={2400}>
                      {files.map((file) => (
                        <StyledImage key={file.filename} src={`/assets/${file.filename}`} alt={title} fit="cover" />
                      ))}
                    </Carousel>
                  </Box>
                </Box>
              )}
            </StyledRecipe>
          </>
        )}
      </ResponsiveContext.Consumer>
    );
  }

  return (
    <Box width="100%" height="50vh" align="center" justify="center">
      <Spinner size="medium" color={theme.colors.primary} align="center" />
    </Box>
  );
};

export default Recipe;
