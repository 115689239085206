import { Button } from 'grommet';
import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ErrorImage from './error-state.png';
import { theme } from '../../theme';

const FallbackContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  color: ${theme.colors.primary};
  font-size: 2rem;
`;

const FallbackTitle = styled.div`
  font-weight: bold;
  text-align: center;
`;

const FallbackDetails = styled.details`
  font-size: 1rem;
  width: 70%;
  height: 20vh;
`;

export const Fallback: FC<FallbackProps> = ({ error: { message, stack } }) => {
  const navigate: NavigateFunction = useNavigate();

  return (
    <FallbackContainer data-testid="error-fallback-container">
      <img src={ErrorImage} alt="Hibaüzenet" />
      <FallbackTitle>Hiba - Az oldalon hiba lépett fel</FallbackTitle>
      <Button
        primary
        onClick={() => {
          navigate('/');
          window.location.reload();
        }}
      >
        Blog újratöltése
      </Button>
      <FallbackDetails>
        <summary>Hiba részletei: {message}</summary>
        <p>{stack}</p>
      </FallbackDetails>
    </FallbackContainer>
  );
};
