import { FC } from 'react';
import styled from 'styled-components';
import { Box, Heading, Paragraph, ResponsiveContext } from 'grommet';

export const StyledHeading = styled(Heading)`
  font-weight: 300;
  font-family: 'Tomatoes';
`;

type IntroProps = {
  titleSize: string;
};

const Intro: FC<IntroProps> = ({ titleSize, ...rest }) => (
  <ResponsiveContext.Consumer>
    {(size) => (
      <>
        <Box direction="row" {...rest} pad={{ bottom: 'small' }}>
          <StyledHeading textAlign="center" size={titleSize}>
            SweetSpot Blog
          </StyledHeading>
        </Box>
        <Paragraph
          textAlign="center"
          size={!size.includes('large') ? 'large' : 'xxlarge'}
          style={{ fontWeight: '600' }}
        >
          Édes Pihenő - Leszállópálya az Édesszájúak bolygóján
        </Paragraph>
        <Paragraph
          textAlign="center"
          size={!size.includes('large') ? 'large' : 'xxlarge'}
          style={{ fontWeight: '400' }}
        >
          Üdvözlünk az Édesszájúak bolygóján, ahol a leszállópálya sütimorzsákkal van kikövezve. Az útjelzőtáblák pedig
          segítenek majd eligazodni a krémes torták, puha piskóták, omlós kekszek, ínycsiklandó piték, selymes
          csokoládéörvények, vagy éppen a habkönnyű desszertek között. Örülnénk, kedves Látogató, ha az édesszájú éned
          minél többször betérne a SweetSpot oldalra egy újabb es újabb édes pihenőre!
        </Paragraph>
      </>
    )}
  </ResponsiveContext.Consumer>
);

export default Intro;
