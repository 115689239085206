import { FC } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Anchor, Avatar, Box, ResponsiveContext } from 'grommet';
import { FacebookOption, Instagram, Twitter } from 'grommet-icons';

import { theme } from '../../theme';

type MediaProps = {
  displayAvatar?: boolean;
};

const Media: FC<MediaProps> = ({ displayAvatar = true }) => {
  const navigate: NavigateFunction = useNavigate();

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box
          direction="row"
          gap={!size.includes('small') ? 'medium' : 'small'}
          justify="center"
          align="center"
          alignContent="center"
        >
          <Anchor
            target="_blank"
            a11yTitle="Kövess az Instagramon!"
            href="https://www.instagram.com/sweetspotlala/"
            icon={<Instagram color={theme.colors.primary} size="medium" />}
          />
          <Anchor
            target="_blank"
            a11yTitle="Kövess a Facebookon!"
            href="https://www.facebook.com/sweetspot.lala"
            icon={<FacebookOption color={theme.colors.primary} size="medium" />}
          />
          <Anchor
            target="_blank"
            a11yTitle="Kövess a Twitteren!"
            href="https://twitter.com/sweetspotlala"
            icon={<Twitter color={theme.colors.primary} size="medium" />}
          />
          {displayAvatar && (
            <Avatar
              src="/images/paulina_avatar.png"
              a11yTitle="Paulina blogszerző"
              size={!size.includes('small') ? 'large' : 'medium'}
              onClick={() => navigate('/author')}
            />
          )}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default Media;
