import { Form, FormField, TextInput, Box, Button, Select, CheckBox, Text, TextArea, Layer, Spinner } from 'grommet';
import { ChangeEvent, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Category, fetchRecipeById, Recipe, removeRecipeById, updateRecipeById } from '../../../api/backendServer';
import { theme } from '../../../theme';

const EditRecipe = (): JSX.Element => {
  const [recipe, setRecipe] = useState<Recipe>();
  const [loading, setLoading] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const { id } = useParams();

  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    if (!recipe && id && !loading) {
      setLoading(true);
      fetchRecipeById(id)
        .then(setRecipe)
        .catch(() => navigate('/login'))
        .finally(() => setLoading(false));
    }
  }, [id, recipe, loading, navigate]);

  const handleValueChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value =
      event.target.type === 'checkbox' ? (event as ChangeEvent<HTMLInputElement>).target.checked : event.target.value;

    if (recipe)
      setRecipe({
        ...recipe,
        [event.target.name]: value,
      });
  };

  const handleIngredientsChange = (event: ChangeEvent<HTMLTextAreaElement>, index: number): void => {
    if (recipe) {
      const { ingredientParts } = recipe;
      ingredientParts[index] = event.target.value;
      setRecipe({
        ...recipe,
        ingredientParts,
      });
    }
  };

  const handleUpdateRecipe = (): void => {
    if (recipe && id) {
      let { ingredientParts } = recipe;
      ingredientParts.reverse().forEach((part) => {
        if (part === '') ingredientParts.pop();
      });
      ingredientParts.reverse();

      setLoading(true);
      updateRecipeById(id, { ...recipe, ingredientParts })
        .then(({ title }) => {
          toast.info(`Sikeresen frissítetted a receptet: ${title}`);
          navigate('/admin');
        })
        .catch(({ message }) => toast.error(`Hiba a recept frissítésekor: ${message}`))
        .finally(() => setLoading(false));
    }
  };

  const handleRemoveRecipe = (): void => {
    if (recipe && id) {
      setShowModal(false);
      setLoading(true);
      removeRecipeById(id)
        .then(({ title }) => {
          toast.info(`Sikeresen törölted a receptet: ${title}`);
          navigate('/admin');
        })
        .catch(({ message }) => toast.error(`Hiba a recept törlésekor: ${message}`))
        .finally(() => setLoading(false));
    }
  };

  if (loading)
    return (
      <Box width="100%" height="50vh" align="center" justify="center">
        <Spinner size="medium" color={theme.colors.primary} align="center" />
      </Box>
    );

  return (
    <Box align="center" justify="center">
      <Box width="80%">
        <Form
          onReset={() => setRecipe(undefined)}
          onSubmit={handleUpdateRecipe}
          onValidate={(validationResults) =>
            setTimeout(() => {
              setValid(validationResults.valid);
            }, 50)
          }
          validate="blur"
          messages={{
            invalid: 'hibás adatok!',
            required: 'kötelező mező!',
          }}
        >
          <FormField
            name="title"
            htmlFor="text-input-title"
            label="Cím *"
            validate={[
              (value?: string) => {
                if (value && value.length < 3) return 'legalább 3 karakter hosszú';
                if (value && value.length > 90) return 'legfeljebb 90 karakter hosszú';
                return undefined;
              },
            ]}
          >
            <TextInput
              id="text-input-title"
              name="title"
              value={recipe?.title || ''}
              onChange={handleValueChange}
              placeholder="Recept főcíme, ami egyértelműen leírja a receptet, legfeljebb 90 karakter hosszúságban. (kötelező)"
            />
          </FormField>
          <FormField
            name="subtitle"
            htmlFor="text-input-subtitle"
            label="Alcím *"
            validate={[
              (value?: string) => {
                if (value && value.length < 3) return 'legalább 3 karakter hosszú';
                if (value && value.length > 90) return 'legfeljebb 90 karakter hosszú';
                return undefined;
              },
            ]}
          >
            <TextInput
              id="text-input-subtitle"
              name="subtitle"
              value={recipe?.subtitle || ''}
              onChange={handleValueChange}
              placeholder="Recept alcíme, ami többet elárul róla, legfeljebb 90 karakter hosszúságban. (kötelező)"
            />
          </FormField>
          <FormField
            name="intro"
            htmlFor="text-input-intro"
            label="Bevezető *"
            validate={[
              (value?: string) => {
                if (value && value.length < 10) return 'legalább 10 karakter hosszú';
                if (value && value.length > 1200) return 'legfeljebb 1200 karakter hosszú';
                return undefined;
              },
            ]}
          >
            <TextArea
              id="text-input-intro"
              name="intro"
              value={recipe?.intro || ''}
              onChange={handleValueChange}
              rows={8}
              placeholder="Rövid bevezető és csalogató szöveg legfeljebb 1200 karakter hosszúságban. (kötelező)"
            />
          </FormField>
          <FormField htmlFor="text-input-ingredients" label="Hozzávalók - 1. rész *">
            <TextArea
              id="text-input-ingredients"
              defaultValue={recipe?.ingredientParts[0] || ''}
              onChange={(event) => handleIngredientsChange(event, 0)}
              rows={5}
              placeholder="pl. Kekszhez|1 dkg kardamom; 2.5 dkg fűszer; 0.5 l tej (1. rész kötelező!)"
            />
          </FormField>
          <FormField htmlFor="text-input-ingredients" label="Hozzávalók - 2. rész">
            <TextArea
              id="text-input-ingredients"
              defaultValue={recipe?.ingredientParts[1] || ''}
              onChange={(event) => handleIngredientsChange(event, 1)}
              rows={5}
              placeholder="pl. Kekszhez|1 dkg kardamom; 2.5 dkg fűszer; 0.5 l tej"
            />
          </FormField>
          <FormField htmlFor="text-input-ingredients" label="Hozzávalók - 3. rész">
            <TextArea
              id="text-input-ingredients"
              defaultValue={recipe?.ingredientParts[2] || ''}
              onChange={(event) => handleIngredientsChange(event, 2)}
              rows={5}
              placeholder="pl. Kekszhez|1 dkg kardamom; 2.5 dkg fűszer; 0.5 l tej"
            />
          </FormField>
          <FormField htmlFor="text-input-ingredients" label="Hozzávalók - 4. rész">
            <TextArea
              id="text-input-ingredients"
              defaultValue={recipe?.ingredientParts[3] || ''}
              onChange={(event) => handleIngredientsChange(event, 3)}
              rows={5}
              placeholder="pl. Kekszhez|1 dkg kardamom; 2.5 dkg fűszer; 0.5 l tej"
            />
          </FormField>
          <FormField htmlFor="text-input-ingredients" label="Hozzávalók - 5. rész">
            <TextArea
              id="text-input-ingredients"
              defaultValue={recipe?.ingredientParts[4] || ''}
              onChange={(event) => handleIngredientsChange(event, 4)}
              rows={5}
              placeholder="pl. Kekszhez|1 dkg kardamom; 2.5 dkg fűszer; 0.5 l tej"
            />
          </FormField>
          <FormField
            name="preparation"
            htmlFor="text-input-preparation"
            label="Elkészítés *"
            validate={[
              (value?: string) => {
                if (value && value.length < 10) return 'legalább 10 karakter hosszú';
                if (value && value.length > 3200) return 'legfeljebb 3200 karakter hosszú';
                return undefined;
              },
            ]}
          >
            <TextArea
              id="text-input-preparation"
              name="preparation"
              value={recipe?.preparation || ''}
              onChange={handleValueChange}
              rows={18}
              placeholder="Tetszőleges szöveg a recept elkészítésének lépéseivel, legfeljebb 3200 karakter hosszúságban. (kötelező)"
            />
          </FormField>
          <FormField name="published" htmlFor="radio-input-published" label="Közzétéve *">
            <CheckBox
              id="radio-input-published"
              name="published"
              checked={recipe?.published || false}
              onChange={handleValueChange}
            />
          </FormField>
          <FormField name="category" htmlFor="select-input-category" label="Kategória *">
            <Select
              id="select-input-category"
              name="category"
              value={recipe?.category || Category.CAKES}
              options={Object.values(Category)}
              onChange={handleValueChange}
            />
          </FormField>
          <Box direction="row" justify="between" margin={{ top: 'medium' }}>
            {<Button color={theme.colors.primary} primary type="submit" label="Frissítés" disabled={!valid} />}
            {<Button color="status-error" type="button" label="Törlés" onClick={() => setShowModal(true)} />}
            {showModal && (
              <Layer onEsc={() => setShowModal(false)} onClickOutside={() => setShowModal(false)}>
                <Box align="center" justify="center" margin="large" gap="medium">
                  <Text size="large" textAlign="center">
                    Biztosan törölni szeretnéd a receptet az adatbázisból?
                  </Text>
                  <Text>{recipe?.title}</Text>
                  <Box direction="row" gap="medium">
                    <Button label="Törlés" color="status-error" onClick={handleRemoveRecipe} />
                    <Button label="Mégsem" color="status-ok" onClick={() => setShowModal(false)} />
                  </Box>
                </Box>
              </Layer>
            )}
            <Button color={theme.colors.primary} type="reset" label="Visszaállítás" />
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default EditRecipe;
