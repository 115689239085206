import {
  Form,
  FormField,
  TextInput,
  Box,
  Button,
  Select,
  CheckBox,
  TextArea,
  Spinner,
  FileInput,
  Image,
  RadioButton,
} from 'grommet';
import { ChangeEvent, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Category,
  CreateRecipe,
  createRecipe,
  removeImage,
  UploadedFile,
  uploadImage,
} from '../../../api/backendServer';
import { theme } from '../../../theme';

const emptyRecipe: CreateRecipe = {
  title: '',
  subtitle: '',
  intro: '',
  ingredientParts: ['', '', '', '', ''],
  preparation: '',
  published: true,
  category: Category.CAKES,
  files: [],
};

const AddRecipe = (): JSX.Element => {
  const [recipe, setRecipe] = useState<CreateRecipe>(emptyRecipe);
  const [loading, setLoading] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [filesResetFlag, setFilesResetFlag] = useState<boolean>(false);

  const navigate: NavigateFunction = useNavigate();

  const handleValueChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    event.preventDefault();
    const value =
      event.target.type === 'checkbox' ? (event as ChangeEvent<HTMLInputElement>).target.checked : event.target.value;
    setRecipe({
      ...recipe,
      [event.target.name]: value,
    });
  };

  const handleIngredientsChange = (event: ChangeEvent<HTMLTextAreaElement>, index: number): void => {
    const { ingredientParts } = recipe;
    ingredientParts[index] = event.target.value;
    setRecipe({
      ...recipe,
      ingredientParts,
    });
  };

  const handleUploadingImages = async (event: ChangeEvent<HTMLInputElement> | undefined): Promise<void> => {
    event?.preventDefault();
    const fileList = event?.target.files;
    const files: UploadedFile[] = [];

    if (fileList?.length !== 4) {
      for await (const uploadedFile of recipe.files) await removeImage(uploadedFile.filename);
      setRecipe({ ...recipe, files: [] });
      setFilesResetFlag((prevState) => !prevState);
      toast.warning('Négy darab képet kell feltölteni egyszerre!');
      return;
    }

    if (fileList) {
      for await (const file of fileList) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        const uploadedFile = await uploadImage(formData);
        if (!uploadedFile) toast.error(`Hiba a kép szerverre történő feltöltésekor!`);
        files.push({ ...uploadedFile, primary: false });
      }
      if (files[0]) files[0].primary = true;
      setRecipe({ ...recipe, files });
    }
  };

  const handlePrimaryImageChange = (event: ChangeEvent<HTMLInputElement>, file: File) => {
    event.preventDefault();
    if (event.target.checked) {
      const modifiedFiles = recipe.files.map((f) => {
        if (f.originalname === file.name) return { ...f, primary: true };
        else return { ...f, primary: false };
      });
      setRecipe({ ...recipe, files: [...modifiedFiles] });
    }
  };

  const handleAddRecipe = (): void => {
    let { ingredientParts } = recipe;
    ingredientParts = ingredientParts.filter((part) => part && part !== '');

    setLoading(true);
    createRecipe({ ...recipe, ingredientParts })
      .then(({ title }) => {
        toast.info(`Sikeresen hozzáadtad a receptet: ${title}`);
        navigate('/admin');
      })
      .catch(({ message }) => toast.error(`Hiba a recept frissítésekor: ${message}`))
      .finally(() => setLoading(false));
  };

  if (loading)
    return (
      <Box width="100%" height="50vh" align="center" justify="center">
        <Spinner size="medium" color={theme.colors.primary} align="center" />
      </Box>
    );

  return (
    <Box align="center" justify="center">
      <Box width="70%">
        <Form
          onReset={() => setRecipe(emptyRecipe)}
          onSubmit={handleAddRecipe}
          onValidate={(validationResults) =>
            setTimeout(() => setValid(validationResults.valid && recipe.files.length === 4), 50)
          }
          validate="blur"
          messages={{
            invalid: 'hibás adatok!',
            required: 'kötelező mező!',
          }}
        >
          <FormField
            name="title"
            htmlFor="text-input-title"
            label="Cím *"
            validate={[
              (value?: string) => {
                if (value && value.length < 3) return 'legalább 3 karakter hosszú';
                if (value && value.length > 90) return 'legfeljebb 90 karakter hosszú';
                return undefined;
              },
            ]}
            required
          >
            <TextInput
              id="text-input-title"
              name="title"
              value={recipe?.title}
              onChange={handleValueChange}
              required
              placeholder="Recept főcíme, ami egyértelműen leírja a receptet, legfeljebb 90 karakter hosszúságban. (kötelező)"
            />
          </FormField>
          <FormField
            name="subtitle"
            htmlFor="text-input-subtitle"
            label="Alcím *"
            validate={[
              (value?: string) => {
                if (value && value.length < 3) return 'legalább 3 karakter hosszú';
                if (value && value.length > 90) return 'legfeljebb 90 karakter hosszú';
                return undefined;
              },
            ]}
            required
          >
            <TextInput
              id="text-input-subtitle"
              name="subtitle"
              value={recipe?.subtitle}
              onChange={handleValueChange}
              placeholder="Recept alcíme, ami többet elárul róla, legfeljebb 90 karakter hosszúságban. (kötelező)"
            />
          </FormField>
          <FormField
            name="intro"
            htmlFor="text-input-intro"
            label="Bevezető *"
            validate={[
              (value?: string) => {
                if (value && value.length < 10) return 'legalább 10 karakter hosszú';
                if (value && value.length > 1200) return 'legfeljebb 1200 karakter hosszú';
                return undefined;
              },
            ]}
            required
          >
            <TextArea
              id="text-input-intro"
              name="intro"
              value={recipe?.intro}
              onChange={handleValueChange}
              rows={8}
              placeholder="Rövid bevezető és csalogató szöveg legfeljebb 1200 karakter hosszúságban. (kötelező)"
            />
          </FormField>
          <FormField htmlFor="text-input-ingredients" label="Hozzávalók - 1. rész *">
            <TextArea
              id="text-input-ingredients"
              defaultValue={recipe?.ingredientParts[0]}
              onChange={(event) => handleIngredientsChange(event, 0)}
              rows={5}
              placeholder="pl. Kekszhez|1 dkg kardamom; 2.5 dkg fűszer; 0.5 l tej (1. rész kötelező!)"
            />
          </FormField>
          <FormField htmlFor="text-input-ingredients" label="Hozzávalók - 2. rész">
            <TextArea
              id="text-input-ingredients"
              defaultValue={recipe?.ingredientParts[1]}
              onChange={(event) => handleIngredientsChange(event, 1)}
              rows={5}
              placeholder="pl. Kekszhez|1 dkg kardamom; 2.5 dkg fűszer; 0.5 l tej"
            />
          </FormField>
          <FormField htmlFor="text-input-ingredients" label="Hozzávalók - 3. rész">
            <TextArea
              id="text-input-ingredients"
              defaultValue={recipe?.ingredientParts[2]}
              onChange={(event) => handleIngredientsChange(event, 2)}
              rows={5}
              placeholder="pl. Kekszhez|1 dkg kardamom; 2.5 dkg fűszer; 0.5 l tej"
            />
          </FormField>
          <FormField htmlFor="text-input-ingredients" label="Hozzávalók - 4. rész">
            <TextArea
              id="text-input-ingredients"
              defaultValue={recipe?.ingredientParts[3]}
              onChange={(event) => handleIngredientsChange(event, 3)}
              rows={5}
              placeholder="pl. Kekszhez|1 dkg kardamom; 2.5 dkg fűszer; 0.5 l tej"
            />
          </FormField>
          <FormField htmlFor="text-input-ingredients" label="Hozzávalók - 5. rész">
            <TextArea
              id="text-input-ingredients"
              defaultValue={recipe?.ingredientParts[4]}
              onChange={(event) => handleIngredientsChange(event, 4)}
              rows={5}
              placeholder="pl. Kekszhez|1 dkg kardamom; 2.5 dkg fűszer; 0.5 l tej"
            />
          </FormField>
          <FormField
            name="preparation"
            htmlFor="text-input-preparation"
            label="Elkészítés *"
            validate={[
              (value?: string) => {
                if (value && value.length < 10) return 'legalább 10 karakter hosszú';
                if (value && value.length > 3200) return 'legfeljebb 3200 karakter hosszú';
                return undefined;
              },
            ]}
            required
          >
            <TextArea
              id="text-input-preparation"
              name="preparation"
              value={recipe?.preparation}
              onChange={handleValueChange}
              rows={18}
              placeholder="Tetszőleges szöveg a recept elkészítésének lépéseivel, legfeljebb 3200 karakter hosszúságban. (kötelező)"
            />
          </FormField>
          <FormField name="published" htmlFor="radio-input-published" label="Közzétéve *">
            <CheckBox
              id="radio-input-published"
              name="published"
              checked={recipe?.published}
              onChange={handleValueChange}
            />
          </FormField>
          <FormField name="category" htmlFor="select-input-category" label="Kategória *" required>
            <Select
              id="select-input-category"
              name="category"
              value={recipe?.category}
              options={Object.values(Category)}
              onChange={handleValueChange}
            />
          </FormField>
          <FormField name="files" htmlFor="file-input-files" label="Képek (4 darab) *" required>
            <FileInput
              key={filesResetFlag.toString()}
              name="files"
              multiple
              maxSize={3500000}
              messages={{
                browse: 'Böngészés',
                files: 'Képek',
                dropPromptMultiple: 'Húzz ide 4 darab 3MB-nál kisebb képet',
                maxFile: 'Csatolj 3MB-nál kisebb képeket!',
                removeAll: 'Távolítsd el az összes képet!',
              }}
              renderFile={(file: File) => (
                <Box width="100%" direction="row" justify="between">
                  <Image src={URL.createObjectURL(file)} height="180px" margin="small" />
                  <RadioButton
                    name="radio"
                    checked={recipe.files.find((f) => f.originalname === file.name)?.primary || false}
                    label="Elsődleges"
                    onChange={(event) => handlePrimaryImageChange(event, file)}
                  />
                </Box>
              )}
              onChange={handleUploadingImages}
            />
          </FormField>
          <Box direction="row" justify="between" margin={{ top: 'medium' }}>
            {<Button color={theme.colors.primary} primary type="submit" label="Mentés" disabled={!valid} />}
            <Button color={theme.colors.primary} type="reset" label="Törlés" />
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default AddRecipe;
