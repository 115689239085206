/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

class HttpService {
  readonly baseUrl: string;

  private axios = {} as AxiosInstance;

  constructor(apiUrl: string) {
    this.baseUrl = apiUrl;
    this.createAxiosInstance();
  }

  async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return (await this.axios.get(this.baseUrl + url, config)).data;
  }

  async put<T>(url: string, payload: any, config?: AxiosRequestConfig): Promise<T> {
    return (await this.axios.put(this.baseUrl + url, payload, config)).data;
  }

  async post<T>(url: string, payload?: any, config?: AxiosRequestConfig): Promise<T> {
    return (await this.axios.post(this.baseUrl + url, payload, config)).data;
  }

  async patch<T>(url: string, payload: any, config?: AxiosRequestConfig): Promise<T> {
    return (await this.axios.patch(this.baseUrl + url, payload, config)).data;
  }

  async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return (await this.axios.delete(this.baseUrl + url, config)).data;
  }

  private createAxiosInstance(): void {
    this.axios = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'json',
    });
  }
}

export const HttpClient = (baseUrl: string): HttpService => new HttpService(baseUrl);
