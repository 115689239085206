export const MAIN_SLIDER_DATA: { [imagePath: string]: string } = {
  '/images/intro/01.jpg': '',
  '/images/intro/02.jpg': '',
  '/images/intro/03.jpg': '',
  '/images/intro/04.jpg': '',
  '/images/intro/05.jpg': '',
  '/images/intro/06.jpg': '',
  '/images/intro/07.jpg': '',
  '/images/intro/08.jpg': '',
  '/images/intro/09.jpg': '',
  '/images/intro/10.jpg': '',
  '/images/intro/11.jpg': '',
  '/images/intro/12.jpg': '',
  '/images/intro/13.jpg': '',
  '/images/intro/14.jpg': '',
  '/images/intro/15.jpg': '',
  '/images/intro/16.jpg': '',
  '/images/intro/17.jpg': '',
};

export const AUTHOR_SLIDER_DATA: { [imagePath: string]: string } = {
  '/images/author/author_1.jpeg': '',
  '/images/author/author_2.jpeg': '',
  '/images/author/author_3.jpeg': '',
  '/images/author/author_4.jpeg': '',
  '/images/author/author_5.jpeg': '',
  '/images/author/author_6.jpeg': '',
  '/images/author/author_7.jpeg': '',
  '/images/author/author_8.jpeg': '',
  '/images/author/author_9.jpeg': '',
  '/images/author/author_10.jpeg': '',
  '/images/author/author_11.jpeg': '',
  '/images/author/author_12.jpeg': '',
};
