import styled from 'styled-components';
import { Box, Heading, ResponsiveContext, Text } from 'grommet';

import Slider from '../Slider/Slider';
import { AUTHOR_SLIDER_DATA } from '../Slider/sliderData';

export const StyledHeading = styled(Heading)`
  font-weight: 200;
  font-family: 'Tomatoes';
`;

const Author = (): JSX.Element => (
  <ResponsiveContext.Consumer>
    {(size) => (
      <Box margin={{ horizontal: size.includes('small') ? 'small' : 'xlarge' }}>
        <StyledHeading alignSelf="center">A szerzőről</StyledHeading>
        <Box margin={{ vertical: 'medium', horizontal: 'small' }} gap="small">
          <Text>Szia, üdvözöllek a konyhámban!</Text>
          <Text>Ez egy gasztroblog, kulináris kalandozásaim térképe, ahol minden megálló egy édes pihenő.</Text>
          <Text>
            Paulina vagyok (barátaimnak és családtagjaimnak csak Lala) - autodidakta cukrász, receptfejlesztő és
            szenvedélyes ételfotós. Azért vagyok itt, hogy megosszam veled kedvenc és kipróbàlt desszertreceptjeimet,
            valamint részletes és egyszerű instrukciókkal lássalak el, amivel főként cukrásztudományodat alapozhatod,
            bővítheted, és persze tesztelheted, ha velem tartasz. Szilárd meggyőződésem, hogy az ételt meg kell osztani
            másokkal ahhoz, hogy a legjobban élvezhessük. Ebben - kezdetektől fogva - segítségemre van öcsém, Bálint,
            aki sok más mellett a blog technikai részéért is felelős.
          </Text>
          <Text>Boldog sütögetést!🙂</Text>
          <Text>Íme néhány véletlenszerű tény rólam:</Text>
          <Text>&#x2022; Jelenleg Szabadkán élek és dolgozom.</Text>
          <Text>&#x2022; Főállásban középiskolai angoltanár vagyok.</Text>
          <Text>&#x2022; Két kisfiú anyukájaként állandó kihívás az életem.😀</Text>
          <Text>
            &#x2022; Legszivesebben pitéket és tartokat sütők. Van valami megmagyarázhatatlanul bájos a rácsos tetejű,
            cakkos szélű, színes gyümölcsöt rejtő desszertekben. Talán mert az összes rajzfilm és mesekönyv így
            ábrázolja a pitéket, vagy mert a filmekben mindig ilyet süt a nagyi. Ki tudja…😉
          </Text>
          <Text>&#x2022; A konyhában a nagy mumusom a kelt tészta, de lassancskán összebarátkozunk.</Text>
          <Text>&#x2022; Kedvenc évszakom az ősz.</Text>
          <Text>
            &#x2022; Szeretem a rock zenét, de pl. a karácsonyi sütogetések alkalmával nálam is a "giccses" klasszikusok
            hallgatása közben sülnek ki a mézeskalácsok.
          </Text>
          <Text>&#x2022; Nagy vágyam, hogy egyszer hivatásszerűen is a sütéssel foglalkozzam...🙂</Text>
        </Box>
        <Slider sliderData={AUTHOR_SLIDER_DATA} />
      </Box>
    )}
  </ResponsiveContext.Consumer>
);

export default Author;
