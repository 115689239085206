import { FC } from 'react';
import { Box, Image, ResponsiveContext, Stack, Text } from 'grommet';

type SliderImageProps = {
  name: string;
  src: string;
  alt: string;
};

const SliderImage: FC<SliderImageProps> = ({ name, src, alt }) => (
  <ResponsiveContext.Consumer>
    {(size) => (
      <Stack anchor="bottom">
        <Box round="xlarge" overflow="hidden" align="center" margin="small" style={{ cursor: 'grab' }}>
          <Image src={src} alt={alt} width={2000} height={2000} />
        </Box>
        <Box>
          {!size.includes('small') && (
            <Text margin={{ bottom: '30px' }} weight="bold" color="white" size="large">
              {name}
            </Text>
          )}
        </Box>
      </Stack>
    )}
  </ResponsiveContext.Consumer>
);

export default SliderImage;
