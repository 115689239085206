import { Anchor, Box, Text, ResponsiveContext, Clock } from 'grommet';

import Logo from '../Logo/Logo';
import Media from '../Media/Media';

const Header = (): JSX.Element => (
  <ResponsiveContext.Consumer>
    {(size) => (
      <Box
        id="header"
        direction="row"
        justify={!size.includes('small') ? 'between' : 'center'}
        alignSelf="center"
        wrap
        gap="large"
        pad="large"
        margin={{ horizontal: 'large' }}
      >
        <Anchor
          href="/"
          icon={<Logo />}
          color="black"
          margin="small"
          label={
            <Text size="xlarge" style={{ fontFamily: 'Tomatoes' }}>
              SweetSpot
              <Clock type="digital" margin={{ left: 'medium' }} />
            </Text>
          }
        />
        <Media />
      </Box>
    )}
  </ResponsiveContext.Consumer>
);

export default Header;
