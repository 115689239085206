import { Form, FormField, Text, TextInput, Box, Button, Spinner } from 'grommet';
import { useContext, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getIdentity, loginAdmin, LoginRequest, LoginResponse, logoutAdmin } from '../../../api/backendServer';
import { Context } from '../../../context/context';
import { theme } from '../../../theme';

const Login = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [recaptcha, setRecaptcha] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);

  const { authenticated, updateAuthenticated } = useContext(Context);

  useEffect(() => {
    getIdentity().then((user: LoginResponse) => {
      if (user) {
        updateAuthenticated(true);
        setName(user.name);
        setEmail(user.email);
      }
    });
  }, []);

  const handleLogin = (loginRequest: LoginRequest): void => {
    setLoading(true);
    loginAdmin(loginRequest)
      .then((response: LoginResponse) => {
        updateAuthenticated(true);
        toast.info(`Sikeresen bejelentkeztél, ${response.name}!`);
      })
      .catch(({ message }) => {
        toast.error(`Hiba a bejelentkezéskor: ${message}`);
      })
      .finally(() => setLoading(false));
  };

  const handleLogout = (): Promise<string | number | void> =>
    logoutAdmin()
      .then(() => {
        updateAuthenticated(false);
        toast.info('Sikeresen kijelentkeztél!');
      })
      .catch(({ message }) => toast.error(`Hiba a kijelentkezéskor: ${message}`));

  if (loading)
    return (
      <Box width="100%" height="50vh" align="center" justify="center">
        <Spinner size="medium" color={theme.colors.primary} align="center" />
      </Box>
    );

  return (
    <Box align="center" justify="center">
      <Box width="medium">
        <Form
          onReset={() => {
            setName('');
            setEmail('');
            setPassword('');
            setRecaptcha(null);
            setValid(false);
            recaptchaRef?.current?.reset();
          }}
          onSubmit={({ value }) => handleLogin(value as LoginRequest)}
          onValidate={(validationResults) =>
            setTimeout(() => {
              setValid(validationResults.valid);
            }, 50)
          }
          validate="blur"
          messages={{
            invalid: 'hibás adatok!',
            required: 'kötelező mező!',
          }}
        >
          {authenticated ? (
            <Box align="center">
              <Text>Sikeresen bejelentkeztél.</Text>
              <Button
                label="Recept adminisztáció"
                color={theme.colors.primary}
                primary
                onClick={() => navigate('/admin')}
              />
            </Box>
          ) : (
            <Box align="center">Jelentkezz be:</Box>
          )}
          <FormField
            name="name"
            htmlFor="text-input-name"
            label="Név"
            validate={[
              { regexp: /^[a-z]/i },
              (name?: string) => {
                if (name && name.length < 4) return 'legalább 4 karakter hosszú';
                if (name && name.length > 30) return 'legfeljebb 30 karakter hosszú';
                return undefined;
              },
            ]}
            required
          >
            <TextInput
              id="text-input-name"
              name="name"
              placeholder="Adminisztrátor Ádám"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </FormField>
          <FormField
            name="email"
            htmlFor="text-input-email"
            label="E-mail cím"
            validate={[
              {
                regexp: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: 'nem e-mail formátum!',
              },
            ]}
            required
          >
            <TextInput
              id="text-input-email"
              name="email"
              placeholder="minta@szolgaltato.com"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </FormField>
          <FormField
            name="password"
            htmlFor="text-input-id"
            label="Jelszó"
            validate={[
              { regexp: /^[a-z]/i },
              (password?: string) => {
                if (password && password.length < 4) return 'legalább 4 karakter hosszú';
                if (password && password.length > 30) return 'legfeljebb 30 karakter hosszú';
                return undefined;
              },
            ]}
            required
          >
            <TextInput
              id="text-input-password"
              name="password"
              placeholder="jelszó"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </FormField>
          <Box align="center">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_SITE_KEY as string}
              onChange={setRecaptcha}
              hl="hu"
            />
          </Box>
          <Box direction="row" justify="between" margin={{ top: 'medium' }}>
            {!authenticated && (
              <Button
                color={theme.colors.primary}
                primary
                type="submit"
                label="Bejelentkezés"
                disabled={!valid || !recaptcha || authenticated}
              />
            )}
            {authenticated && <Button color={theme.colors.secondary} label="Kijelentkezés" onClick={handleLogout} />}
            <Button color={theme.colors.primary} type="reset" label="Törlés" />
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default Login;
