import { createGlobalStyle } from 'styled-components';

import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    color: ${theme.colors.secondary};
    height: 100%;

  * {
    font-family: 'Montserrat', serif;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    margin: 0;
  }

  button {
    margin: 0;
    padding: 0;
    border: 0;
    font: 400 1.5rem 'Montserrat', sans-serif;
    text-align: left;
  }

  body > iframe {
    pointer-events: none;
  }

  ::-webkit-scrollbar {
    width: 30px;
  }

  ::-webkit-scrollbar-track {
    background: 'black';
    box-shadow: inset 0 0 5px 'black';
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: 'grey';
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
  background: 'white';
  }
`;
