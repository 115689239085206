import { createSearchParams, NavigateFunction, useNavigate } from 'react-router-dom';
import { Grid, GridColumnsType, ResponsiveContext } from 'grommet';

import { categories } from './categoriesData';
import Card from '../Card/Card';
import { Category } from '../../api/backendServer';

const Categories = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const categoryClickedHandler = (category?: Category): void => {
    if (category)
      navigate({
        pathname: 'posts',
        search: createSearchParams({
          category: category,
        }).toString(),
      });
  };

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Grid id="categories" align="start" columns={getColumns(size)} gap={size === 'small' ? 'small' : 'medium'}>
          {categories.map(({ name, image, description, category }) => (
            <Card
              key={name}
              name={name}
              image={image}
              description={description}
              category={category}
              onCardClick={() => categoryClickedHandler(category)}
            />
          ))}
        </Grid>
      )}
    </ResponsiveContext.Consumer>
  );
};

const getColumns = (size: string): GridColumnsType => {
  switch (size) {
    case 'xxsmall':
    case 'xsmall':
      return 'full';
    case 'small':
      return '1/2';
    case 'medium':
    case 'large':
    default:
      return '1/3';
  }
};

export default Categories;
