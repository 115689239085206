import { ResponsiveContext, Box, Text } from 'grommet';

import Logo from '../Logo/Logo';
import Media from '../Media/Media';
import FooterContent from './Footer.content';

const Footer = ({ ...rest }): JSX.Element => (
  <ResponsiveContext.Consumer>
    {(size) => (
      <Box
        direction={size.includes('xsmall') ? 'column' : 'row'}
        justify="between"
        border={{ side: 'top', size: '2px', color: 'light-4' }}
        pad={{ vertical: 'large' }}
        margin={{ horizontal: size.includes('small') ? 'large' : 'xlarge' }}
        {...rest}
      >
        <Box
          direction={size.includes('xsmall') ? 'row' : 'column'}
          gap="large"
          align="center"
          justify="between"
          margin={{ bottom: size.includes('xsmall') ? 'large' : undefined }}
        >
          <Box gap="small" direction="row-responsive" align="center" pad={{ horizontal: 'small' }}>
            <Logo />
            {!size.includes('small') && (
              <Text weight="bold" size="large" style={{ fontFamily: 'Tomatoes' }}>
                SweetSpot
              </Text>
            )}
          </Box>
          <Media displayAvatar={false} />
        </Box>
        <FooterContent />
      </Box>
    )}
  </ResponsiveContext.Consumer>
);

export default Footer;
