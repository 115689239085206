import { FC } from 'react';
import { Box } from 'grommet';

type SectionProps = {
  children: React.ReactNode;
  width: string;
};

const Section: FC<SectionProps> = ({ children, width, ...rest }) => (
  <Box align="center" pad="large" {...rest}>
    <Box width={width}>{children}</Box>
  </Box>
);

export default Section;
