import { DataTable, Box, Text, Spinner, Button } from 'grommet';
import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { fetchRecipes, Recipe as RecipeProps } from '../../../api/backendServer';
import { theme } from '../../../theme';
import { CategoryMap } from '../../Recipe/Recipe';

const TABLE_COLUMNS = [
  {
    property: 'id',
    header: 'ID',
    size: 'xxsmall',
  },
  {
    property: 'title',
    primary: true,
    header: 'Cím',
    size: 'medium',
  },
  {
    property: 'subtitle',
    header: 'Alcím',
    size: 'medium',
  },
  {
    property: 'category',
    header: 'Kategória',
    render: ({ category }: RecipeProps) => <Text>{CategoryMap[category]}</Text>,
  },
  {
    property: 'published',
    header: 'Közzétéve',
    render: ({ published }: RecipeProps) => <Text>{published ? 'Igen' : 'Nem'}</Text>,
  },
  {
    property: 'createdAt',
    header: 'Feltöltve',
    render: ({ createdAt }: RecipeProps) => <Text>{createdAt && new Date(createdAt).toLocaleString('hu-HU')}</Text>,
  },
  {
    property: 'updatedAt',
    header: 'Módosítva',
    render: ({ updatedAt }: RecipeProps) => <Text>{updatedAt && new Date(updatedAt).toLocaleString('hu-HU')}</Text>,
  },
];

const Table = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const [fetching, setFetching] = useState<boolean>(false);
  const [recipes, setRecipes] = useState<RecipeProps[]>([]);

  useEffect(() => {
    setFetching(true);
    fetchRecipes()
      .then(({ items }) => setRecipes(items))
      .catch(({ message }) => toast.error(`Hiba a receptek betöltésekor: ${message}`))
      .finally(() => setFetching(false));
  }, []);

  if (fetching)
    return (
      <Box width="100%" height="50vh" align="center" justify="center">
        <Spinner size="medium" color={theme.colors.primary} align="center" />
      </Box>
    );

  return (
    <Box align="center">
      <Button label="Új recept" color={theme.colors.primary} margin="medium" onClick={() => navigate('/admin/new')} />
      <DataTable
        background={{
          header: 'dark-3',
          body: ['light-1', 'light-3'],
          footer: 'dark-3',
        }}
        border={{ body: 'bottom' }}
        sortable
        sort={{ property: 'createdAt', direction: 'desc' }}
        resizeable
        paginate
        columns={TABLE_COLUMNS}
        data={recipes}
        onClickRow={({ datum: { id } }) => navigate(`/admin/${id}`)}
      />
    </Box>
  );
};

export default Table;
