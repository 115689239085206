import { Grommet } from 'grommet';
import { FC } from 'react';

import { customTheme, theme } from '../../theme';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Section from '../Section/Section';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }) => (
  <Grommet theme={customTheme} full background={theme.colors.background}>
    <Header />
    {children}
    <Section width="100%">
      <Footer />
    </Section>
  </Grommet>
);

export default Layout;
