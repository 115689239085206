import { FC } from 'react';
import { Box, Image, Heading, Text, ResponsiveContext } from 'grommet';
import styled from 'styled-components';

import { Category } from '../../api/backendServer';

const Root = styled(Box)`
  min-width: 100%;
  transition: background-color 0.3s ease-in-out;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 2px 4px rgb(0 0 0 / 7.5%);
  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
    box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
  }
`;

const ImageContainer = styled(Box)`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    z-index: 2;
  }
  &:hover {
    &:after {
      background: rgba(0, 0, 0, 0);
    }
    cursor: pointer;
  }
`;

const StyledHeading = styled(Heading)`
  --line-height: 24px;
  height: calc(2em + (var(--line-height) / 2));
  overflow: hidden;
  margin-bottom: 0;
  @media (min-width: 769px) {
    --line-height: 28px;
  }
`;

export const StyledImage = styled(Image)``;

export type CardProps = {
  name: string;
  image: string;
  description: string;
  category?: Category;
  onCardClick?: () => void;
};

const Card: FC<CardProps> = ({ name, image, description, onCardClick }) => (
  <ResponsiveContext.Consumer>
    {(size) => (
      <Root round="xxsmall" overflow="hidden" onClick={onCardClick}>
        <ImageContainer height="medium">
          <StyledImage src={image} alt={name} fit="cover" />
        </ImageContainer>
        <Box pad={{ horizontal: 'small' }}>
          <Box
            margin={{ top: size.includes('small') ? 'medium' : 'small' }}
            direction="row"
            align="center"
            justify="between"
          >
            <StyledHeading level="3" margin="none" size="small">
              {name}
            </StyledHeading>
          </Box>
          <Text
            size={!size.includes('small') ? 'medium' : 'small'}
            color="dark-2"
            margin={{ vertical: 'small' }}
            truncate
          >
            {description}
          </Text>
        </Box>
      </Root>
    )}
  </ResponsiveContext.Consumer>
);

export default Card;
