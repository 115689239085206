import { Stack } from 'grommet';
import styled from 'styled-components';

const ImageContainer = styled.img`
  width: 80px;
  height: 80px;

  @media only screen and (max-width: 600px) {
    width: 48px;
    height: 48px;
  }
`;

const Logo = (): JSX.Element => (
  <Stack anchor="center">
    <ImageContainer src="favicon.ico" alt="SweetSpot blog logo" />
  </Stack>
);

export default Logo;
