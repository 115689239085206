import { FC, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Context } from '../context/context';
import Table from '../components/Admin/Table/Table';
import App from '../components/App/App';
import Author from '../components/Author/Author';
import Contact from '../components/Contact/Contact';
import Login from '../components/Admin/Login/Login';
import Recipe from '../components/Recipe/Recipe';
import Recipes from '../components/Recipes/Recipes';
import EditRecipe from '../components/Admin/EditRecipe/EditRecipe';
import AddRecipe from '../components/Admin/AddRecipe/AddRecipe';

export const Router: FC<{}> = () => {
  const { authenticated } = useContext(Context);

  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/posts">
        <Route index element={<Recipes />} />
        <Route path=":id" element={<Recipe />} />
      </Route>
      <Route path="/author" element={<Author />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" element={<Login />} />
      {authenticated && (
        <Route path="/admin">
          <Route index element={<Table />} />
          <Route path=":id" element={<EditRecipe />} />
          <Route path="new" element={<AddRecipe />} />
        </Route>
      )}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
