import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = (): null => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById('header');
      element?.scrollIntoView({ behavior: 'smooth' });
    }, 10);
  }, [pathname]);

  return null;
};
