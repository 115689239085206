import { Email } from '../components/Contact/Contact';
import { HttpClient as HttpClient } from './client/HttpService';

export interface Pagination<T> {
  items: T[];
  totalCount: number;
  page: number;
  limit: number;
}

export interface LoginRequest {
  email: string;
  password: string;
  name: string;
}

export interface LoginResponse {
  id: number;
  name: string;
  email: string;
}

export enum Category {
  CAKES = 'CAKES',
  COOKIES = 'COOKIES',
  DESSERTS = 'DESSERTS',
  PIES = 'PIES',
  CUPCAKES_MUFFINS_BROWNIES = 'CUPCAKES_MUFFINS_BROWNIES',
  PASTRIES = 'PASTRIES',
}

export interface UploadedFile {
  originalname: string;
  filename: string;
  primary?: boolean;
}

export interface File {
  id: number;
  filename: string;
  primary: boolean;
}

export interface Recipe {
  id: number;
  title: string;
  subtitle: string;
  intro: string;
  ingredientParts: string[];
  preparation: string;
  published: boolean;
  category: Category;
  files: File[];
  createdAt: string;
  updatedAt: string;
}

export interface CreateRecipe {
  title: string;
  subtitle: string;
  intro: string;
  ingredientParts: string[];
  preparation: string;
  published: boolean;
  category: Category;
  files: UploadedFile[];
}

const httpClient = HttpClient('/api');

export const getIdentity = async (): Promise<LoginResponse> => httpClient.get('/auth/identity');

export const loginAdmin = async (loginRequest: LoginRequest): Promise<LoginResponse> =>
  httpClient.post('/auth/sign-in', loginRequest);

export const logoutAdmin = async (): Promise<void> => httpClient.post('/auth/sign-out');

export const fetchRecipeById = async (id: string): Promise<Recipe> => httpClient.get(`/recipes/${id}`);

export const fetchRecipes = async (page?: number, limit?: number): Promise<Pagination<Recipe>> =>
  httpClient.get('/recipes', {
    params: {
      page,
      limit,
    },
  });

export const fetchRecipesByCategory = async (
  category: Category,
  page?: number,
  limit?: number,
): Promise<Pagination<Recipe>> =>
  httpClient.get('/recipes/category', {
    params: {
      category,
      page,
      limit,
    },
  });

export const fetchRecipesByKeyword = async (
  search: string,
  page?: number,
  limit?: number,
): Promise<Pagination<Recipe>> =>
  httpClient.get('/recipes/search', {
    params: {
      search,
      page,
      limit,
    },
  });

export const uploadImage = async (payload: FormData): Promise<UploadedFile> =>
  httpClient.post('/files', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const removeImage = async (file: string): Promise<string> =>
  httpClient.delete('/files', {
    params: {
      file,
    },
  });

export const createRecipe = async (payload: CreateRecipe): Promise<Recipe> => httpClient.post('/recipes', payload);

export const updateRecipeById = async (id: string, payload: Recipe): Promise<Recipe> =>
  httpClient.patch(`/recipes/${id}`, payload);

export const removeRecipeById = async (id: string): Promise<Recipe> => httpClient.delete(`/recipes/${id}`);

export const sendMessage = async (email: Email): Promise<string> => httpClient.post(`/email`, email);
